require("@rails/ujs").start()
require("turbolinks").start()
/*require("@rails/activestorage").start()*/

/*require("@popperjs/core")*/
// require("packs/jquery-3.2.1.min")
import jquery from 'jquery';
window.$ = window.jquery = jquery;
require("packs/bootstrap.min")

// import 'slick-carousel/slick/slick.min.js';
import Swiper from 'swiper';
//import 'swiper/swiper-bundle.css';

import AOS from 'aos';
import 'aos/dist/aos.css';


document.addEventListener('turbo:load', function() {
  const swiper = new Swiper('.swiper-container', {
    // Optional parameters for customization
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
});

$(document).on('click', "#send-otp", function(e){  
  e.preventDefault();
  var mobileNumber = $('#demo_request_mobile_number').val()
  if (mobileNumber){
    var county_code = "+91" //$(".iti__selected-flag").attr('title').split(':')[1].trim(' ')
    $('#demo_request_mobile_number').hide()
    $('#dummy_mobile_number').val(county_code + mobileNumber)
    $('#dummy_mobile_number').show();
    $('#send-otp').hide();
    $('#edit-mobile').show();
    $('#otp-field').show();
    setTimeout(function(){
      setHeight();
    }, 10)
    $.ajax({
      url: '/demo_requests/send_otp',
      method: 'post',
      data: {mobile_number: county_code + mobileNumber},
      success: function(res) {
       console.log("OTP sent successfully!");
      }
    })
  }
  else {
    $('#mobile-err').show();
    setTimeout(function(){  
      $('#mobile-err').hide();
    }, 3000)
  }
})


/*document.addEventListener('DOMContentLoaded', () => {
  
  var controlsContainer = document.getElementById('industryButton'),
    allImages = document.querySelectorAll('.image'),
    imagesContainer = document.getElementById('industryImages');
    controlsContainer.onclick = function(e){
      var target = e.target,
          dataTarget = target.getAttribute('data-image'),      
          activeImage = document.getElementById(dataTarget);
      
      if(dataTarget){
        for( var i = 0; i < allImages.length; i++){
          allImages[i].removeAttribute('data-active');
        }
        activeImage.setAttribute('data-active', 'active');
      }  
    }

    controlsContainer.onmouseover = function(e){
      var target = e.target,
          dataTarget = target.getAttribute('data-image'),      
          activeImage = document.getElementById(dataTarget);
      
      if(dataTarget){
        for( var i = 0; i < allImages.length; i++){
          allImages[i].removeAttribute('data-active');
        }
        activeImage.setAttribute('data-active', 'active');
      }  
    }

});
*/
$(document).ready(function() {

  AOS.init();

  $(".demo-request-modal ").on("click", function(){
    $("#book-demo-modal").modal('show');
  });

  $('.subscribe-now').on('click', function(){
    $(".hero-form").css({'border':"1px solid red", "box-shadow":"1px 1px 15px 1px red"});
    $(".hero-form").focus().offset().top;
  });

  $(window).scroll(function(){
    if ($(this).scrollTop() > 80) {
      $('.page-header').addClass('fixed');
    } else {
      $('.page-header').removeClass('fixed');
    }
  });

  if ($(window).width() < 767) {
    $("#navbarNav").addClass("sm-nav");
    $('.page-header').addClass('fixed');
  }else{
    $('.page-header').removeClass('fixed');
  }


  var $video  = $('video'), $window = $(window); 
  $(window).resize(function(){      
    var height = $window.height();
    $video.css('height', height);
    
    var videoWidth = $video.width(),
        windowWidth = $window.width(),
    marginLeftAdjust =   (windowWidth - videoWidth) / 2;
    
    $video.css({
        'height': height, 
        'marginLeft' : marginLeftAdjust
    });
  }).resize();


  var modal = document.getElementById("subscribModal");
    var span = document.getElementsByClassName("close")[0];
    span.onclick = function() {
      modal.style.cssText = `
        display: none;
        transition: all 0.3s ease;
      `;
    }
    function closeModal(){
      modal.style.cssText = `
        display: none;
        transition: all 0.3s ease;
      `;
    }
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.cssText = `
          display: none;
          transition: all 0.3s ease;
        `;
      }
    }

});

